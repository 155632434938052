import React from "react";
import Layout from "../components/layout/layout";
import { graphql} from "gatsby";
import { Hero } from "../components/Hero/Hero";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Heading } from "../components/Heading/Heading";
import { Content } from "../components/Content/Content";
import  CareerFormSection  from "../components/CareerFormSection/CareerFormSection";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const Career = () => {
  const { t } = useTranslation();
  return (
    <Layout
      pageTitle={t("career.hero.title")}
      description={t("career.hero.subtitle")}
      narrowLayout
    >
      <Hero
        title={t("career.hero.title")}
        description={t("career.hero.subtitle")}
        image="career/career-img.jpg"
        btnLink="#career-form"
        btnText={t("global.apply-now")}
        withSpacing
      />
      <Container wide>
        <Column
          columns_mobile="12"
          columns_tablet="12"
          columns_desktop="12"
          custom_class="no-gutter@lg"
        >

          <Heading  level="h2" />
          <Content>
            <p>{t("career.content.text1")}</p>
            <p>{t("career.content.text2")}</p>
            <ul className="list list--content">
              <li className="list__item">{t("career.content.list.item1")}</li>
              <li className="list__item">{t("career.content.list.item2")}</li>
              <li className="list__item">{t("career.content.list.item3")}</li>
            </ul>
          </Content>
        </Column>
        <Column
          columns_mobile="12"
          columns_tablet="12"
          columns_desktop="12"
          custom_class="no-gutter@lg"
        >

        <CareerFormSection/>

        </Column>
      </Container>
    </Layout>
  );
};

export default Career;
