import React from "react";
import './content.scss';

export const Content = ({ children }) => {
  return (
    <>
      <div
        className="content"
      >
        <div className="content__main-holder">
            {children}
        </div>
      </div>
    </>
  );
};
