import React, { useRef, useState } from "react";
import { Button } from "../Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";
import axios from "axios";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const phoneRegExp = /^[0-9 ()+-]+$/;
const schema = yup
  .object({
    name: yup
      .string()
      .required(<Trans i18nKey="contact.form.validation.name.required"></Trans>)
      .min(3, <Trans i18nKey="contact.form.validation.name.min"></Trans>),
    email: yup
      .string()
      .email(<Trans i18nKey="contact.form.validation.email.not-valid"></Trans>)
      .required(<Trans i18nKey="contact.form.validation.email.required"></Trans>),
    phone: yup
      .string()
      .required(<Trans i18nKey="contact.form.validation.phone.required"></Trans>)
      .matches(phoneRegExp, <Trans i18nKey="contact.form.validation.phone.not-valid"></Trans>)
      .min(5, <Trans i18nKey="contact.form.validation.phone.min"></Trans>),
    country: yup.string(),
    message: yup.string(),
    gdpr: yup
    .boolean()
    .oneOf([true], <Trans i18nKey="contact.form.validation.gdpr"></Trans>),
  })
  .required();

const CareerFormSection = ({}) => {
  const dropzoneRef = useRef();
  const {
    register,
    handleSubmit,
    onError,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccessMessage = () => {
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 5000);
  };

  const dropzoneConfig = {
    postUrl: `${process.env.GATSBY_API_URL}/career-form`,
  };
  const djsConfig = {
    maxFilesize: 10,
    parallelUploads: 6,
    maxFiles: 6,
    acceptedFiles: ".docx,.doc,.pdf",
    autoProcessQueue: false,
    addRemoveLinks: true,
    uploadMultiple: true,
    paramName: () => "file",
  };

  const handleInit = (dropzoneInstance) => {
    dropzoneRef.current = dropzoneInstance;
  };

  const handleSendingMultiple = (data, xhr, formData) => {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const formValues = getValues();
    for (const key in formValues) {
      if (key === "field") {
        formData.append(key, formValues[key][1]);
      } else {
        formData.append(key, formValues[key]);
      }
    }
  };

  const handleSubmitSuccessful = () => {
    setLoading(false);
    showSuccessMessage();

    reset({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      gdpr: false,
      files: "",
    });
    dropzoneRef.current.removeAllFiles();
  };

  const handleSubmitError = () => {
    setLoading(false);
    setIsError(true);
  };

  const eventHandlers = {
    init: handleInit,
    sendingmultiple: handleSendingMultiple,
    successmultiple: handleSubmitSuccessful,
    error: handleSubmitError,
  };

  const onSubmit = (data) => {
    if (dropzoneRef.current?.getQueuedFiles().length > 0) {
      dropzoneRef.current?.processQueue();
    } else {
      const formData = new FormData();
      for (const key in data) {
        if (key === "field") {
          formData.append(key, data[key][1]);
        } else {
          formData.append(key, data[key]);
        }
      }

      axios
        .post(`${process.env.GATSBY_API_URL}/contact-form`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          handleSubmitSuccessful();
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  };

  const validateFileUploads = () => {
    const hasQueuedFiles = dropzoneRef.current?.getQueuedFiles().length > 0;
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="container" id="career-form">
        <div className="gr-12 no-gutter@md">
          <div role="form" className="form form--career">
            <form
              onSubmit={(e) => {
                handleSubmit(onSubmit)(e);
              }}
              method="POST"
            >
              <div className="row">
                <div className="gr-12 gr-6@md">
                  <fieldset className="form__fieldset">
                    <input
                      type="text"
                      className={errors.name && "error"}
                      {...register("name")}
                      size="40"
                      placeholder={t("contact.form.field.full-name")}
                    />
                    <label htmlFor="name">{t("contact.form.field.full-name")}</label>
                    <label className="error">{errors.name?.message}</label>
                    {errors.name && <div className="error-icon"></div>}
                  </fieldset>
                </div>
                <div className="gr-12 gr-6@md">
                  <fieldset className="form__fieldset">
                    <input
                      type="email"
                      className={errors.email && "error"}
                      {...register("email")}
                      size="40"
                      placeholder={t("contact.form.field.email")}
                    />
                    <label htmlFor="email"> {t("contact.form.field.email")}</label>
                    <label className="error">{errors.email?.message}</label>
                    {errors.email && <div className="error-icon"></div>}
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="gr-12 gr-6@md">
                  <fieldset className="form__fieldset">
                    <input
                      type="tel"
                      className={errors.phone && "error"}
                      {...register("phone")}
                      size="40"
                      placeholder={t("contact.form.field.phone-number")}
                    />
                    <label htmlFor="phone">{t("contact.form.field.phone-number")}</label>
                    <label className="error">{errors.phone?.message}</label>
                    {errors.phone && <div className="error-icon"></div>}
                  </fieldset>
                </div>
                <div className="gr-12 gr-6@md">
                  <fieldset className="form__fieldset">
                    <input
                      type="text"
                      className={errors.country && "error"}
                      {...register("country")}
                      size="40"
                      placeholder={t("contact.form.field.country")}
                    />
                    <label htmlFor="country">
                      {t("contact.form.field.country")}
                    </label>
                    <label className="error">{errors.country?.message}</label>
                    {errors.country && <div className="error-icon"></div>}
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="gr-12">
                  <fieldset className="form__fieldset form__fieldset--about">
                    <textarea
                      {...register("message")}
                      placeholder={t("contact.form.field.something-about-yourself")}
                      rows="4"
                    />
                    <label htmlFor="message">{t("contact.form.field.something-about-yourself")}</label>
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="gr-12">
                  <fieldset className="form__fieldset form__fieldset--dropzone">
                    <DropzoneComponent
                      config={dropzoneConfig}
                      eventHandlers={eventHandlers}
                      djsConfig={djsConfig}
                    >
                      <div className="dz-message">
                        {t("contact.form.field.attach-files")}
                      </div>
                    </DropzoneComponent>
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="gr-12">
                  <fieldset className="form__fieldset form__fieldset--gdpr">
                    <div className="checkbox-group">
                      <input type="checkbox" {...register("gdpr")} id="gdpr" />
                      <label htmlFor="gdpr">
                        {" "}
                        {t("contact.form.field.consent")}
                      </label>
                      <label className="error">{errors.gdpr?.message}</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="row">
                {isSuccess && (
                  <div className="gr-12">
                    <div className="form__notification">
                      <span>{t("contact.form.validation.sent")}</span>
                    </div>
                  </div>
                )}
                {isError && (
                  <div className="gr-12">
                    <div className="form__error">
                      <span>{t("contact.form.validation.error")}</span>
                    </div>
                  </div>
                )}
                <div className="gr-12">
                  <Button
                    type="submit"
                    submitBtn
                    primary
                    withIcon
                    loading={loading}
                    disabled={loading}
                    data-sitekey="6Ld-SrEZAAAAAD9uhrzNWBaTDuQRCTuFQnKI4z54"
                    data-callback="recaptchaCallback"
                    data-badge="inline"
                    data-size="invisible"
                    onClick={() => handleSubmit(onSubmit, onError)}
                  >
                    {loading ? (
                      <Trans i18nKey="contact.form.field.sending"></Trans>
                    ) : (
                      <Trans i18nKey="contact.form.field.apply-now"></Trans>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerFormSection;
